@import 'prismjs/themes/prism-coy.css';

#Map {
    position: relative;
    width: 100%;
    height: 100%;
}

.p-column-filter-overlay {
    padding: 5px;
    background: #ffffff;
    border: 1px solid #6d6d6d;
    border-radius: 5px;
}

.p-column-filter-constraint {
    margin-bottom: 5px;
}

.p-column-filter-buttonbar {
    .p-button {
        margin: 2px;
    }
}

.p-column-filter-menu-button-active {
    background-color: #477fdf;
    color: #fff;
    border-radius: 5px;
}

#MapBase {
    position: absolute;
    top: 70px;
    margin-left: 8px;
    padding: 0;

    .p-card-body {
        padding: 0;

        .p-card-content {
            padding: 4px 8px;
        }
    }

    .switch-text {
        display: inline-block;
        margin: 2px 4px 0 0;
        vertical-align: top;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .p-inputswitch {
        vertical-align: top;
        margin: 2px 0;
    }
    @media (max-width: 500px) {    .jump-btns {
        display: inline;

        button {
            margin-left: 6px;
            padding: 4px 8px;
            background-color: #ced4da;
            color: #333;
            font-weight: bold;
        }
    }}

    @media (min-width: 501px){
    .jump-btns {
        display: inline;
        margin-left: 10px;

        button {
            margin-left: 6px;
            padding: 4px 8px;
            background-color: #ced4da;
            color: #333;
            font-weight: bold;
            min-width: 70px;
        }
    }
    .jump-btns-active {
        // box-shadow: 0 0 3px #7655bf; /* Adjust the color and spread as needed */   
        // color: #7655bf;
        // stroke: #7655bf;
        // fill: #7655bf;
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #55bf96;
    }
}
        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
}

.collapsible-pane {
    .collapse-content {
        overflow: hidden;
        display: inline-block;
    }

    button.minimizer {
        display: inline-block;
        margin: 4px;
        background-color: #5cb85c;
        cursor: pointer;

        // .p-button-label {
        //     display: none;
        // }
    }

    button.minimizer.top {
        width: calc(100% - 8px);
        max-height: 18px;
        padding: 1px 0;
    }
}



@media (max-width: 500px) {
    .filter-style {
        width: 345px;
        margin-bottom: 15px;
    }
}

@media (min-width: 501px) {
    .filter-style {
        width: 450px;
        margin-bottom: 15px;
    }
}

.preference-dropdown {
    width: 100%;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.bottom-overlay {

    bottom: 0px;
    padding: 2px;
    background: #ffffff00;
    transition: width 0.3s;

    button.minimizer.top {
        display: block;
        height: 3rem;
        width: 3rem;

        margin-bottom: 1rem;
        color: #888;
        background: #fff;
    }

    .collapse-content {

        &.in,
        &.out {
            transition: all 0.5s ease-out;
        }

        &.out {
            width: auto;
        }

        &.in {
            width: 0;
            height: 0;
            background: #ffffff00;
        }
    }

}

#LocSensMap {
    .mapboxgl-ctrl-bottom-right {
        display: none;
    }

    .mapboxgl-ctrl-bottom-left {
        display: none;
    }

    .map-overlay {
        position: absolute;
        bottom: 10px;
        margin-left: 8px;

        .loc-table {
            max-width: 33vw;
            min-width: 5%;
            display: inline-block;

            .p-card-content {
                padding: 0;
            }
        }

        .refresh-bar {
            position: relative;
            margin: 1px 0;
            padding: 6px 8px;
            border-radius: 2px;
            background-color: #FFF;

            .refresh-label {
                position: relative;
                z-index: 1;
                margin: 0 12px;
                min-width: 130px;
                text-align: center;
                font-weight: bold;
            }

            .p-progressbar {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 16px);
                margin: 4px 8px;
            }
        }
    }

    .map-overlay-help {
        position: absolute;
        bottom: 30px;
        right: 0;
        margin-right: 30px;
        font-size: 2.5rem;
        color: #477fdf;
        background: #efefef;
        border-radius: 20px; /* Adjust the radius as needed */

    }

    .locTable {
        padding: 0;
        z-index: 3;
        max-height: 60vh;
        min-width: 280px;

        .p-datatable-wrapper {
            max-height: 50vh;
            overflow-y: auto;
        }

        .card-title {
            display: inline-block;
            width: 100%;

            button.filter-reset {
                background-color: #D95349;
                border: #D95349;

                .p-button-label {
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                }
            }

            button.toggle {
                color: #000000;
                float: right;
            }
        }

        .spinner {
            align-items: center;
            justify-content: center;
            width: 250px;
            height: 250px;
        }
    }

    #Map {
        .acaa-marker {
            display: inline-block;
            border-radius: 5px;
            text-align: center;
            vertical-align: middle;
            color: white;
            font-size: 1.2rem;
            padding: 2px 5px;
            min-height: 20px;
            opacity: 0.8;
            border: 3px solid #000;

            &.out-of-range {
                border: 3px dashed #000;
            }
        }

        .triangle-down {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #4f4f4f;
            margin: auto;
            margin-top: 2px;
        }

        .mapboxgl-popup-content {
            padding-bottom: 4px;
            font-size: 14px;

            >div {
                display: inline-block;
                width: 200px;
                padding: 4px;

                &.old {
                    background-color: #FF000055;
                }

                .visual {
                    position: relative;
                    border: 1px solid #ededed;

                    .name {
                        position: absolute;
                        bottom: 0px;
                        width: 100%;
                        padding: 5px 3px;
                        font-size: 18px;
                        color: #FFFFFF;
                        opacity: 0.9
                    }

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                .data {
                    margin-top: 8px;
                    margin-bottom: 4px;

                    .time {
                        float: left;

                        i {
                            margin-top: 3px;
                            margin-right: 4px;
                        }

                        span {
                            vertical-align: top;
                        }
                    }

                    .asset-label {
                        font-weight: bold;
                        float: right;
                    }
                }
            }
        }
    }
}